import { createBrowserOnlyLazyComponent } from '@dbh/react-lazy';
import createUseBoundEventCreator from '@dbh/create-use-bound-event-creator';
import { hotelReviewsEntryContentName } from '@dbh/entry-content-names';
import { entryContentShown } from '@dbh/with-entry-content';

const hotelReviewsEntryContentShown=()=>entryContentShown(hotelReviewsEntryContentName);const useHotelReviewsEntryContentShown=createUseBoundEventCreator(hotelReviewsEntryContentShown);

var index = createBrowserOnlyLazyComponent(()=>import('./component-a340d6ae.js'/* webpackChunkName: "dbh.hotel-reviews-entry-content" */));

export { index as default, hotelReviewsEntryContentShown, useHotelReviewsEntryContentShown };
